import React, { SyntheticEvent, useRef } from 'react'
import { Dispatch } from "redux"
import { ProductModelCategoryList, CurrentPlateDesign, TextColor, PlateCategoryMetaInfo, VehicleType, Brand, PlateColor, PlateDesignModel, TransactionType, PlateModel } from '@models/ProductModels';
import { DropdownProps, Icon } from 'semantic-ui-react';
import { MessagePlateLocations } from '@utils/Constant';
import { PlateService } from '@services/PlateService';
import { calculateDefaultPrice } from './designPreviewHelper';
import { buildPlateImageUrl, isDefaultCombination } from '@utils/Helpers';
import { MobilePreviewContainer, PlateImageContainer, PlateImage } from '@components/PlateDesignImagesContainer/PlateDesignImagesContainer.styles';
import Axios from 'axios';
import { MobileHeader, StaticDropDown, MobileHeaderParagraph, MobileHeaderTitle, ButtonWrapper } from './PlateDesignsPreview.styles';
import { PlateDesignPricing } from '@components/PlateDesignDetailsContainer/PlateDesignDetails.styles';
import MobileDesignSlider from './MobileDesignSlider';
import { MobilePlateDesignStyleItemContainer } from '@components/PlateDesignStyleItemContainer';
import { integer } from 'aws-sdk/clients/cloudfront';
import { BasketLineRequestModel } from '@models/Basket/BasketLineRequestModel';
import { addBasketLineRequest, deleteBasketLineRequest } from '@redux/actions';
import { clearBasket } from "@redux/actions/checkout"
import { connect } from 'react-redux';
import { currentBasketSelector } from '@redux/selectors/basketSelector';
import { Record } from "immutable"
import CardContainer from '@components/CardContainer/CardContainer';
import { BrandsText, PlateDesignInfo, PlateDesignInfoContainer, PlateDesignName, PlatePriceTag, PrcingFrom } from '@components/PlateDesignCard/PlateDesignCard.styles';
import theme from '@styles/theme';
import { FontColor } from '@components/PlateDesignStyleContainer/MobilePlateDesignStyleContainer.styles';
import KpButton from '@elements/KpButton/KpButton';
interface Props {
    apiUrl: string
    formattedCombination: string
    plateDesigns: Array<{
        products: ProductModelCategoryList,
        metaInfo: PlateCategoryMetaInfo
    }>,
    selectedPlateDesign: {
        products: ProductModelCategoryList,
        metaInfo: PlateCategoryMetaInfo
    }
    currentPlateDesign: CurrentPlateDesign
    vehicleType: VehicleType
    transactionType: TransactionType
    title: string
    onSelectCard: (data: string, id: number) => void
    isGift?: boolean
    groupList: any
    paragraph: string
    subTitle: string,
    groupName: any    
}

interface States {
    loading: boolean
    brand?: Brand
    infoBannerVisible: boolean
    loadingPrice: boolean
    plateColor: PlateColor
    frontPlate: PlateDesignModel
    backPlate: PlateDesignModel
    vehicleType: VehicleType
    categoryName: string
    price: number
    fontMap: {
        CharacterMapSmall: { [key: string]: number },
        CharacterMapLarge: { [key: string]: number }
    } | undefined,
    expandedGroup: any,
    productId: any,
    displayCard: boolean
}

class NewMobilePlateDesignPreview extends React.Component<Props, States>{

    CancelToken = Axios.CancelToken;
    source = this.CancelToken.source();


    constructor(props: Props) {
        super(props);
        var productId = ""
        if (typeof window !== "undefined") {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            productId = urlParams.get('productid') || ""
        }

        this.state = {
            loading: true,
            infoBannerVisible: false,
            categoryName: props.selectedPlateDesign.metaInfo.name,
            vehicleType: props.vehicleType,
            loadingPrice: !isDefaultCombination(props.formattedCombination, props.vehicleType),
            plateColor: props.currentPlateDesign.plateColor,
            frontPlate: props.currentPlateDesign.frontPlate,
            backPlate: props.currentPlateDesign.backPlate,
            brand: props.currentPlateDesign.brand,
            fontMap: undefined,
            price: calculateDefaultPrice(props.selectedPlateDesign.metaInfo, props.transactionType),
            expandedGroup: [this.props.groupName != undefined && 999],
            productId: productId,
            displayCard: props.selectedPlateDesign && productId != '' ? true : false
        }
    }

    loadMessageFontMap = () => {
        PlateService.getCaptionFontMap(this.props.apiUrl, this.source.token).then(data => {
            this.setState({ fontMap: data });
        })
    }

    updatePlatePrice = () => {
        this.calculatePlatePrice(this.state.frontPlate, this.state.backPlate);
    }

    showInfoBanner = () => {
        this.setState({ infoBannerVisible: true })
    }
    hideInfoBanner = () => {
        this.setState({ infoBannerVisible: false })
    }
    calculatePlatePrice = (frontPlate: PlateDesignModel, backPlate: PlateDesignModel) => {
        if (!isDefaultCombination(this.props.formattedCombination, this.props.vehicleType)) {
            this.setState({ loadingPrice: true });
            PlateService.getPlatePrice(
                this.props.apiUrl,
                this.props.transactionType.id,
                frontPlate.plateDesignCode,
                frontPlate.plateSize.id,
                backPlate.plateDesignCode,
                backPlate.plateSize.id,
                this.props.vehicleType.id,
                this.props.formattedCombination,
                this.source.token).then((response) => {
                    const price = response.TotalPriceIncGST;
                    this.setState({ price: price, loadingPrice: false });
                }).catch(err => {
                    this.setState({ loadingPrice: false });
                    console.log(err);
                });
        }
    }
    componentDidUpdate() {
        if (this.state.categoryName !== this.props.selectedPlateDesign.metaInfo.name) {
            this.setState({
                categoryName: this.props.selectedPlateDesign.metaInfo.name,
                plateColor: this.props.currentPlateDesign.plateColor,
                frontPlate: this.props.currentPlateDesign.frontPlate,
                backPlate: this.props.currentPlateDesign.backPlate,
                brand: this.props.currentPlateDesign.brand
            });
            this.calculatePlatePrice(this.props.currentPlateDesign.frontPlate, this.props.currentPlateDesign.backPlate);
        }
    }

    getSelectedPlateDesignCategoryById = (id: any) => {
        let formattedCombinationNoTrailingWhitespace = this.props.formattedCombination
        if (id =='' || id == undefined) id = this.props.plateDesigns[0].products.categories[0].products[0].id
        var url = '/add-to-cart/?combination=' + formattedCombinationNoTrailingWhitespace + '&productid='+id+'&vt=' + this.state.vehicleType.id + "&tt=" + this.props.transactionType.id
        if (typeof window !== "undefined") {
            window.location.href = url;
        }
    }

    selectDesign = (name: string, id: number) => {
        this.setState({productId: id})        
        
        let plateDesign = id ? this.props.plateDesigns.find((item :any) => item.products.getPlateCategoryByPlateDesignCode(id, id) !== undefined) : this.props.plateDesigns[0];
        
        if (name) {
            this.setState({displayCard: true})
            this.props.onSelectCard(plateDesign!.metaInfo.name, id);
        }
        window.scrollTo(0,0)
    }

    handlePlateColorChange = (currentPlateDesign: CurrentPlateDesign, plateColorName: string) => {
        this.setState({ loading: true });
        const currentDesign = this.props.selectedPlateDesign.products.buildPlateDesignModelByPlateColorAndBrand(currentPlateDesign, plateColorName, this.state.brand);
        this.calculatePlatePrice(currentDesign.frontPlate, currentDesign.backPlate);
        this.setState({ loading: false, brand: currentDesign.brand, plateColor: currentDesign.plateColor, frontPlate: currentDesign.frontPlate, backPlate: currentDesign.backPlate });
    }

    //TODO: the plate design code should be same during text color change. use plate design code instead but be aware of front and back plate design code are different
    handlePlateTextColorChange = (currentPlateDesign: CurrentPlateDesign, textColor: TextColor) => {
        this.setState({ loading: true });
        const currentDesign = this.props.selectedPlateDesign.products.buildPlateDesignPropsByPlateColorAndTextColor(this.state.plateColor, textColor.id, this.state.brand, undefined, currentPlateDesign.frontPlate, currentPlateDesign.backPlate);
        this.setState({ loading: false, brand: currentDesign.brand, plateColor: currentDesign.plateColor, frontPlate: currentDesign.frontPlate, backPlate: currentDesign.backPlate });
    }
    handleFrontPlateSizeChange = (event: SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
        this.setState({ loading: true });
        const plateSizeId = data.value as number;
        const newFrontPlateMoel = this.props.selectedPlateDesign.products.buildPlateModelForPlateSizeChange(this.state.frontPlate, this.state.backPlate, plateSizeId);
        const newPlateDesignModel = this.mapPlateModelToPlateDesignModel(newFrontPlateMoel)
        this.calculatePlatePrice(newPlateDesignModel, this.state.backPlate);
        this.setState({ loading: false, frontPlate: newPlateDesignModel });
    }
    handleBackPlateSizeChange = (event: SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
        this.setState({ loading: true });
        const plateSizeId = data.value as number;
        const newBackPlateMoel = this.props.selectedPlateDesign.products.buildPlateModelForPlateSizeChange(this.state.frontPlate, this.state.backPlate, plateSizeId);
        const newPlateDesignModel = this.mapPlateModelToPlateDesignModel(newBackPlateMoel)
        this.calculatePlatePrice(this.state.frontPlate, newPlateDesignModel);
        this.setState({ loading: false, backPlate: newPlateDesignModel });
    }

    mapPlateModelToPlateDesignModel = (plateModel: PlateModel): PlateDesignModel => {
        const captionPosition = plateModel.captionPositon;
        let topMessageColor = undefined;
        let bottomMessageColor = undefined;
        let topMessageText = undefined;
        let bottomMessageText = undefined;
        if (captionPosition !== undefined) {
            if (captionPosition.value === MessagePlateLocations.TopAndBottom || captionPosition.value === MessagePlateLocations.TopOnly) {
                topMessageColor = plateModel.messageColors === undefined ? undefined : plateModel.messageColors[0];
                topMessageText = this.loadExistingTopMessage();
            }
            if (captionPosition.value === MessagePlateLocations.TopAndBottom || captionPosition.value === MessagePlateLocations.BottomOnly) {
                bottomMessageColor = plateModel.messageColors === undefined ? undefined : plateModel.messageColors[0];
                bottomMessageText = this.loadExistingBottomMessage();
            }
        }

        const newBackPlateDesignModel: PlateDesignModel = {
            plateDesignCode: plateModel.plateDesignCode,
            plateSize: plateModel.plateSize,
            textColor: plateModel.textColor,
            captionPositon: plateModel.captionPositon,
            topMessageColor: topMessageColor,
            bottomMessageColor: bottomMessageColor,
            topMessageText: topMessageText,
            bottomMessageText: bottomMessageText
        }
        return newBackPlateDesignModel;
    }
    loadExistingTopMessage = () => {
        if (this.state.frontPlate.topMessageText !== undefined && this.state.frontPlate.topMessageText.trim().length > 0) {
            return this.state.frontPlate.topMessageText;
        }
        if (this.state.backPlate.topMessageText !== undefined && this.state.backPlate.topMessageText.trim().length > 0) {
            return this.state.backPlate.topMessageText;
        }
        return undefined;
    }

    loadExistingBottomMessage = () => {
        if (this.state.frontPlate.bottomMessageText !== undefined && this.state.frontPlate.bottomMessageText.trim().length > 0) {
            return this.state.frontPlate.bottomMessageText;
        }
        if (this.state.backPlate.bottomMessageText !== undefined && this.state.backPlate.bottomMessageText.trim().length > 0) {
            return this.state.backPlate.bottomMessageText;
        }
    }
    handleCaptionPositionChange = (event: SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
        const position = data.value as string //bottom_only, top_only, or top_and_bottom
        const currentDesign = this.props.selectedPlateDesign.products.buildPlateDesignPropsByPlateColorAndTextColor(this.state.plateColor, this.state.frontPlate.textColor.id, undefined, position);
        const newFrontPlate = { ...currentDesign.frontPlate, topMessageText: this.state.frontPlate.topMessageText, bottomMessageText: this.state.frontPlate.bottomMessageText, topMessageColor: this.state.frontPlate.topMessageColor, bottomMessageColor: this.state.frontPlate.bottomMessageColor }
        const newBackPlate = { ...currentDesign.backPlate, topMessageText: this.state.backPlate.topMessageText, bottomMessageText: this.state.backPlate.bottomMessageText, topMessageColor: this.state.backPlate.topMessageColor, bottomMessageColor: this.state.backPlate.bottomMessageColor }

        this.setState({ loading: false, frontPlate: newFrontPlate, backPlate: newBackPlate });
    }
    handleBottomMessageColorChange = (color: TextColor) => {
        if (this.state.frontPlate.captionPositon !== undefined &&
            (this.state.frontPlate.captionPositon.value === MessagePlateLocations.BottomOnly || this.state.frontPlate.captionPositon.value === MessagePlateLocations.TopAndBottom)) {
            const newFrontPlate = { ...this.state.frontPlate, bottomMessageColor: color };
            this.setState({ frontPlate: newFrontPlate });
        }
        if (this.state.backPlate.captionPositon !== undefined &&
            (this.state.backPlate.captionPositon.value === MessagePlateLocations.BottomOnly || this.state.backPlate.captionPositon.value === MessagePlateLocations.TopAndBottom)) {
            const newBackPlate = { ...this.state.backPlate, bottomMessageColor: color };
            this.setState({ backPlate: newBackPlate });
        }
    }
    handleTopMessageColorChange = (color: TextColor) => {
        if (this.state.frontPlate.captionPositon !== undefined &&
            (this.state.frontPlate.captionPositon.value === MessagePlateLocations.TopOnly || this.state.frontPlate.captionPositon.value === MessagePlateLocations.TopAndBottom)) {
            const newFrontPlate = { ...this.state.frontPlate, topMessageColor: color };
            this.setState({ frontPlate: newFrontPlate });
        }
        if (this.state.backPlate.captionPositon !== undefined &&
            (this.state.backPlate.captionPositon.value === MessagePlateLocations.TopOnly || this.state.backPlate.captionPositon.value === MessagePlateLocations.TopAndBottom)) {
            const newBackPlate = { ...this.state.backPlate, topMessageColor: color };
            this.setState({ backPlate: newBackPlate });
        }
    }
    handleLockedMessageColorChange = (color: TextColor) => {
        if (this.state.frontPlate.captionPositon !== undefined &&
            this.state.frontPlate.captionPositon.value === MessagePlateLocations.TopAndBottom) {
            const newFrontPlate = { ...this.state.frontPlate, topMessageColor: color, bottomMessageColor: color };
            this.setState({ frontPlate: newFrontPlate });
        }
        if (this.state.backPlate.captionPositon !== undefined && this.state.backPlate.captionPositon.value === MessagePlateLocations.TopAndBottom) {
            const newBackPlate = { ...this.state.backPlate, topMessageColor: color, bottomMessageColor: color };
            this.setState({ backPlate: newBackPlate });
        }
    }
    handleTopMessageTextChange = (event: SyntheticEvent<HTMLElement, Event>, data: { value: string }) => {
        let text = data.value.toUpperCase();
        text = text.replace(/[^a-zA-Z0-9\t\n .,/<>?;:"'`!@#$%^&*()[\]{}_+=|~-]*/g, "");
        if (this.state.frontPlate.captionPositon !== undefined && (
            this.state.frontPlate.captionPositon.value === MessagePlateLocations.TopAndBottom || this.state.frontPlate.captionPositon.value === MessagePlateLocations.TopOnly)) {
            this.setState(prevState => ({
                ...prevState,
                frontPlate: {
                    ...prevState.frontPlate,
                    topMessageText: text
                }
            }))
        }
        if (this.state.backPlate.captionPositon !== undefined && (
            this.state.backPlate.captionPositon.value === MessagePlateLocations.TopAndBottom || this.state.backPlate.captionPositon.value === MessagePlateLocations.TopOnly)) {
            this.setState(prevState => ({
                ...prevState,
                backPlate: {
                    ...prevState.backPlate,
                    topMessageText: text
                }
            }))
        }
    }
    handleBottomMessageTextChange = (event: SyntheticEvent<HTMLElement, Event>, data: { value: string }) => {
        let text = data.value.toUpperCase();
        text = text.replace(/[^a-zA-Z0-9\t\n .,/<>?;:"'`!@#$%^&*()[\]{}_+=|~-]*/g, "");
        if (this.state.frontPlate.captionPositon !== undefined && (
            this.state.frontPlate.captionPositon.value === MessagePlateLocations.TopAndBottom || this.state.frontPlate.captionPositon.value === MessagePlateLocations.BottomOnly)) {
            this.setState(prevState => ({
                ...prevState,
                frontPlate: {
                    ...prevState.frontPlate,
                    bottomMessageText: text
                }
            }))
        }
        if (this.state.backPlate.captionPositon !== undefined && (
            this.state.backPlate.captionPositon.value === MessagePlateLocations.TopAndBottom || this.state.backPlate.captionPositon.value === MessagePlateLocations.BottomOnly)) {
            this.setState(prevState => ({
                ...prevState,
                backPlate: {
                    ...prevState.backPlate,
                    bottomMessageText: text
                }
            }))
        }
    }
    handleBrandChange = (event: SyntheticEvent<HTMLElement, Event>, data: DropdownProps, currentPlateDesign: CurrentPlateDesign) => {
        const currentDesign = this.props.selectedPlateDesign.products.buildPlateDesignByBrand(currentPlateDesign, data.value as string)
        this.calculatePlatePrice(currentDesign.frontPlate, currentDesign.backPlate);
        this.setState({ loading: false, brand: currentDesign.brand, plateColor: currentDesign.plateColor, frontPlate: currentDesign.frontPlate, backPlate: currentDesign.backPlate });
    }

    handleToggle = (index: integer) => {
        var expandedGroup = this.state.expandedGroup

        if(expandedGroup.includes(index)){
            const item = expandedGroup.indexOf(index);
            expandedGroup.splice(item, 1);
        } else {
            expandedGroup: expandedGroup.push(index)
        }

        this.setState({
            expandedGroup: expandedGroup
        })
    }

    getTopCatpion = (
        frontPlate: PlateDesignModel,
        backPlate: PlateDesignModel
      ) => {
        if (
          (frontPlate.captionPositon &&
            (frontPlate.captionPositon.value ===
              MessagePlateLocations.TopAndBottom ||
              frontPlate.captionPositon.value === MessagePlateLocations.TopOnly)) ||
          (backPlate.captionPositon &&
            (backPlate.captionPositon.value ===
              MessagePlateLocations.TopAndBottom ||
              backPlate.captionPositon.value === MessagePlateLocations.TopOnly))
        ) {
          const topMessageColor = frontPlate.topMessageColor
            ? frontPlate.topMessageColor
            : backPlate.topMessageColor
              ? backPlate.topMessageColor
              : undefined
          const topMessageText = frontPlate.topMessageText
            ? frontPlate.topMessageText
            : backPlate.topMessageText
              ? backPlate.topMessageText
              : undefined
          if (topMessageColor && topMessageText) {
            return {
              captionText: topMessageText,
              captionColourId: topMessageColor.id,
            }
          } else {
            return undefined
          }
        } else {
          return undefined
        }
      }

      getBottomCatpion = (
        frontPlate: PlateDesignModel,
        backPlate: PlateDesignModel
      ) => {
        if (
          (frontPlate.captionPositon &&
            (frontPlate.captionPositon.value ===
              MessagePlateLocations.TopAndBottom ||
              frontPlate.captionPositon.value ===
              MessagePlateLocations.BottomOnly)) ||
          (backPlate.captionPositon &&
            (backPlate.captionPositon.value ===
              MessagePlateLocations.TopAndBottom ||
              backPlate.captionPositon.value === MessagePlateLocations.BottomOnly))
        ) {
          const bottomMessageColor = frontPlate.bottomMessageColor
            ? frontPlate.bottomMessageColor
            : backPlate.bottomMessageColor
              ? backPlate.bottomMessageColor
              : undefined
          const bottomMessageText = frontPlate.bottomMessageText
            ? frontPlate.bottomMessageText
            : backPlate.bottomMessageText
              ? backPlate.bottomMessageText
              : undefined
          if (bottomMessageColor && bottomMessageText) {
            return {
              captionText: bottomMessageText,
              captionColourId: bottomMessageColor.id,
            }
          } else {
            return undefined
          }
        } else {
          return undefined
        }
      }

      getPlateDetails = (
        frontPlate: PlateDesignModel,
        backPlate: PlateDesignModel
      ) => {
        if (frontPlate.plateDesignCode === backPlate.plateDesignCode) {
          return {
            plateDesignId: frontPlate.plateDesignCode,
            textColorId: frontPlate.textColor.id,
            plateSizeId: frontPlate.plateSize.id,
            dualPlateDesignId: undefined,
            dualPlateTextColorId: undefined,
            dualPlateSizeId: undefined,
          }
        } else {
          return {
            plateDesignId: frontPlate.plateDesignCode,
            textColorId: frontPlate.textColor.id,
            plateSizeId: frontPlate.plateSize.id,
            dualPlateDesignId: backPlate.plateDesignCode,
            dualPlateTextColorId: backPlate.textColor.id,
            dualPlateSizeId: backPlate.plateSize.id,
          }
        }
      }


    handleAddToCart = () => {
        //continue to submit form
        const topCaption = this.getTopCatpion(
            this.props.currentPlateDesign.frontPlate,
            this.props.currentPlateDesign.backPlate
        )
        const bottomCaption = this.getBottomCatpion(
            this.props.currentPlateDesign.frontPlate,
            this.props.currentPlateDesign.backPlate
        )
        const plates = this.getPlateDetails(
            this.props.currentPlateDesign.frontPlate,
            this.props.currentPlateDesign.backPlate
        )

        let formattedCombinationNoTrailingWhitespace = this.props
        .formattedCombination

        // remove trailing whitespace if it's only one whitespace at the end
        if (
        formattedCombinationNoTrailingWhitespace.toString().charAt(
            formattedCombinationNoTrailingWhitespace.length - 1
        ) === " " &&
        formattedCombinationNoTrailingWhitespace.toString().charAt(
            formattedCombinationNoTrailingWhitespace.length - 2
        ) !== " "
        ) {
        formattedCombinationNoTrailingWhitespace = formattedCombinationNoTrailingWhitespace.toString().substring(
            0,
            formattedCombinationNoTrailingWhitespace.length - 1
        )
        }

        const topCaptionText = topCaption ? topCaption.captionText : ""
        const bottomCaptionText = bottomCaption ? bottomCaption.captionText : ""

        const basketLine: BasketLineRequestModel = {
        combination: formattedCombinationNoTrailingWhitespace.toString()
            .split(" ")
            .join(""),
        combination_Layout: formattedCombinationNoTrailingWhitespace.toString(),
        transactionTypeId: this.props.transactionType.id,
        vehicleTypeId: this.props.vehicleType.id,
        plateDesignId: plates.plateDesignId,
        foregroundColourId: plates.textColorId,
        plateSizeId: plates.plateSizeId,
        dualPlateDesignId: plates.dualPlateDesignId,
        dualPlateForegroundColourId: plates.dualPlateTextColorId,
        dualPlateSizeId: plates.dualPlateSizeId,
        captionTopColourId: topCaption ? topCaption.captionColourId : undefined,
        captionTopText: topCaptionText,
        captionBottomColourId: bottomCaption
            ? bottomCaption.captionColourId
            : undefined,
        captionBottomText: bottomCaptionText,
        isGift: this.props.isGift,
        }

    }

    removeSelectedDesign = () => {
        this.setState({displayCard: false})
    }

    render() {
        const { apiUrl, formattedCombination, plateDesigns, selectedPlateDesign, vehicleType, transactionType, title, groupList, groupName } = this.props;

        const isDefaultComb = isDefaultCombination(formattedCombination, vehicleType);
        const currentPlateDesign: CurrentPlateDesign = {
            brand: this.state.brand,
            plateColor: this.state.plateColor,
            frontPlate: this.state.frontPlate,
            backPlate: this.state.backPlate
        };
        const plateImageModel = {
            formattedCombination: formattedCombination,
            vehicleType: vehicleType,
            frontPlate: this.state.frontPlate,
            backPlate: this.state.backPlate
        }

        var bestSelling = groupList.filter((x: any) => x.groupName == 'Best Selling')
        if(bestSelling.length === 0){
            bestSelling = groupList
        }

        var filteredGroupList = groupList.filter((x: any) => x.groupName != 'Best Selling')
        var selectedGroupList = groupList.filter((x: any) => x.groupName === groupName)

        var id = this.state.productId
        var fontColors = 1
        filteredGroupList.filter((y: any) =>
            y.items.filter((z: any) =>
                z.products.categories.filter((a: any) =>
                    a.products.filter((b: any) => {
                        if(b.id === parseInt(this.state.productId)){
                            id = z.products.categories[0].products[0].id
                            fontColors = z.products.categories[0].products[0].variants.length
                        } else {
                            id =''
                        }  
                    })
                )
            )
          )

        const imageWidth = "100%" 
        const plateImageUrl = buildPlateImageUrl(apiUrl, currentPlateDesign.frontPlate, formattedCombination, 275 )
        const price = calculateDefaultPrice(selectedPlateDesign.metaInfo, transactionType)
          
        return (
            <MobilePreviewContainer>
                <MobileHeaderTitle>{title}</MobileHeaderTitle>
                <MobileHeaderParagraph>{this.props.paragraph}</MobileHeaderParagraph>

                {
                    (selectedPlateDesign && this.state.displayCard ) && 
                    <div  onClick={this.removeSelectedDesign}>
                        <CardContainer selected={true}>  
                            <PlateDesignInfoContainer>
                                <PlateDesignInfo>
                                    <PlateDesignName fontSize='18px'>{selectedPlateDesign.metaInfo.name}</PlateDesignName>
                                    <PlateDesignPricing isMobile={true}>
                                    {selectedPlateDesign.products.brands !== undefined ? (
                                        selectedPlateDesign.products.brands.length > 1 && (
                                        <BrandsText>
                                            {selectedPlateDesign.products.brands.length} Designs
                                        </BrandsText>
                                        )
                                    ) : (
                                        <div>{fontColors > 1 ? 
                                            <FontColor fontSize='14px'>+{fontColors || 0} font colours</FontColor> : <FontColor fontSzie='14px'>1 font colour</FontColor>
                                            }
                                        </div>
                                        )}                              
                                        
                                        <PlatePriceTag color={theme.brand.primary}>
                                        <PrcingFrom>{transactionType.code.charAt(0).toUpperCase() + transactionType.code.slice(1)}</PrcingFrom> ${price}
                                        </PlatePriceTag>
                                    </PlateDesignPricing>
                                </PlateDesignInfo>                   
                            </PlateDesignInfoContainer>
                            <PlateImageContainer>                    
                                <PlateImage
                                    src={plateImageUrl}
                                    width={imageWidth}
                                    alt={`${formattedCombination} plate image`}
                                />
                            </PlateImageContainer>
                        </CardContainer>
                        <ButtonWrapper id='select-style-continue'>
                            <KpButton fullWidth="mobile" id='select-style-continue' color={theme.brand.colors.blue} buttonType='primary' onClick={() => this.getSelectedPlateDesignCategoryById(this.state.productId)}>
                                    CONTINUE
                            </KpButton>
                        </ButtonWrapper>
                    </div>
                }

                <MobileHeader dangerouslySetInnerHTML={{ __html: this.props.subTitle }}/>
                <MobileDesignSlider
                    apiUrl={this.props.apiUrl}
                    plateDesigns={plateDesigns}
                    selectedDesignMetaInfoName={selectedPlateDesign.metaInfo.name}
                    currentPlateDesign={currentPlateDesign}
                    vehicleType={vehicleType}
                    formattedCombination={formattedCombination}
                    //getSelectedPlateDesignCategory={this.getSelectedPlateDesignCategory}
                    transactionType={transactionType}
                    onSelectCard={this.selectDesign}
                    bestSelling={bestSelling}
                />
                <br/>
                <React.Fragment>
                

                {filteredGroupList.map((data: any, index: integer) => {
                    return (
                        <React.Fragment>
                        { data.groupName === groupName ?
                        <React.Fragment>
                            <StaticDropDown onClick={() => this.handleToggle(999)}>
                                {data.groupName} 
                                <span> ({data.designCounts} Designs)</span>
                            <Icon name={this.state.expandedGroup.includes(999) ? 'angle up': 'angle down'} size='large' /></StaticDropDown>
                            {this.state.expandedGroup.includes(999) &&
                                data.items.map((item: any, index: integer) => {
                                return (
                                <MobilePlateDesignStyleItemContainer apiUrl={this.props.apiUrl}
                                    key={index}
                                    vehicleType={vehicleType}
                                    currentPlateDesign={currentPlateDesign}
                                    formattedCombination={formattedCombination}
                                    data={item}
                                    transactionType={transactionType}
                                    onSelectCard={this.selectDesign}
                                    productId={this.state.productId}
                                    />)
                                })
                            }
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <StaticDropDown onClick={() => this.handleToggle(index)}>
                                {data.groupName}
                                <span> ({data.designCounts} Designs)</span>
                            <Icon name={this.state.expandedGroup.includes(index) ? 'angle up': 'angle down'} size='large' /></StaticDropDown>
                            {this.state.expandedGroup.includes(index) &&
                                data.items.map((item: any, index: integer) => {
                                return (
                                <MobilePlateDesignStyleItemContainer apiUrl={this.props.apiUrl}
                                    key={index}
                                    vehicleType={vehicleType}
                                    currentPlateDesign={currentPlateDesign}
                                    formattedCombination={formattedCombination}
                                    data={item}
                                    transactionType={transactionType}
                                    onSelectCard={this.selectDesign}
                                    productId={this.state.productId}
                                    />)
                                })
                            }
                        </React.Fragment>
                        }
                        </React.Fragment>
                    )
                })}

                </React.Fragment>
            </MobilePreviewContainer>
        )
    }
}

const mapStateToProps = (state: Record<any>) => {
    const currentBasket = currentBasketSelector(state)
    return {
      basketId: currentBasket.get("basketId"),
      loading: currentBasket.get("loading"),
      success: currentBasket.get("success"),
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  addBasketLine(
    apiUrl: string,
    basketId: string,
    basketLine: BasketLineRequestModel,
    isCheckout: boolean
  ) {
    dispatch(addBasketLineRequest(apiUrl, basketId, basketLine, isCheckout))
  },
  clearBasket() {
    dispatch(clearBasket())
  },
  removeBasketLine(
    apiUrl: string,
    basketId: string,
    basketLineId: string) { dispatch(deleteBasketLineRequest(apiUrl, basketId, basketLineId)) }
})

const newMobilePlateDesignPreview = connect(
    mapStateToProps,
    mapDispatchToProps
  )(NewMobilePlateDesignPreview)

export default newMobilePlateDesignPreview;
